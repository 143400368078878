import type { AnalyticsEvent } from './analyticsEvents';
import type { AuthEvent } from './authEvents';
import type { UXEvent } from './uxEvents';

export * from './authEvents';
export * from './analyticsEvents';
export * from './uxEvents';
export type OutsideHeaderEvent<T> = T & {
  isOutgoing?: boolean;
};
export type EventTypeGuard = AnalyticsEvent | AuthEvent | UXEvent;
export enum OutsideHeaderEventDomain {
  ANALYTICS = 0,
  AUTH = 1,
  UX = 2
}
export type HeaderEventHandlerFunction = (
  event: OutsideHeaderEvent<EventTypeGuard>
) => void;
export type SubscribeFunction = (
  key: string,
  listener: HeaderEventHandlerFunction
) => null | void;
export type DispatchFunction = (
  key: string,
  event: OutsideHeaderEvent<EventTypeGuard>
) => void;
export type OutsideHeaderWindowObject = {
  dispatch: DispatchFunction;
  isMounted: boolean;
  isReady: boolean;
  subscribe: SubscribeFunction;
};
export enum BroadcastEventType {
  BROADCAST_READY_STATE = 'BROADCAST_READY_STATE'
}
