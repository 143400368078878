import type { OutsideHeaderEventDomain } from '.';

export enum UXEventID {
  OPEN_PROFILE_INFORMATION = 0,
  OPEN_PROFILE_MEMBERSHIP = 1,
  CLOSE_PROFILE_OVERLAY = 2,
  TOGGLE_MEGA_NAV = 3,
  TOGGLE_PROFILE_STUB = 4,
  TRIGGER_JOIN_NOW = 5
}
export type UXEvent = {
  domain: OutsideHeaderEventDomain;
  id: UXEventID;
};
