import { APPLICATION_URL } from 'helpers/constants';
import type { WatchSeo } from 'helpers/types/jwplayer-subgraph';
import type { NextSeoProps } from 'next-seo/lib/types';

type OgMediaTypes = {
  ogImageType?: string;
  ogType?: string;
};

const defaultImage = {
  height: 900,
  type: 'image/png',
  url: 'https://wp.watch.outsideonline.com/wp-content/uploads/2024/04/Outside-TV-Web-Snippet-v3.png',
  width: 1600
};

export function parsePageSeo({
  asPath,
  ogImageType = null,
  ogType = 'website',
  ...seo
}: OgMediaTypes & Partial<WatchSeo> & { asPath: string }): NextSeoProps {
  const {
    description,
    ogDescription,
    ogImage,
    ogImageHeight,
    ogImageWidth,
    ogTitle,
    title
  } = seo || {};

  const image = ogImage
    ? {
        ...defaultImage,
        height: ogImageHeight,
        type: ogImageType,
        url: ogImage,
        width: ogImageWidth
      }
    : defaultImage;

  // router.asPath shows homepage as "/index"
  // the issue and solution is described here: https://github.com/vercel/next.js/issues/35345
  const path = asPath === '/index' ? '/' : asPath;

  return {
    canonical: `${APPLICATION_URL}${path.split('?')[0]}`,
    description: description || ogDescription,
    nofollow: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
    noindex: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
    openGraph: {
      description: ogDescription,
      images: [image],
      title: ogTitle,
      type: ogType
    },
    title: title || ogTitle
  };
}
