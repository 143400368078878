import { UserTypes } from 'helpers/types/WebMicrofrontends';
import { useAuthService } from '@pocketoutdoormedia/auth-state-service';

type Output = {
  email: Nullable<string>;
  isAnonymousUser: boolean;
  isBrandPassUser: boolean;
  isFreeUser: boolean;
  isLoggedIn: boolean;
  isOutsidePlusUser: boolean;
  userType: Nullable<UserTypes>;
  uuid: Nullable<string>;
};

const DEFAULT_USER_TYPE: Output = {
  email: null,
  isAnonymousUser: true,
  isBrandPassUser: false,
  isFreeUser: false,
  isLoggedIn: false,
  isOutsidePlusUser: false,
  userType: null,
  uuid: null
};

export const useUserType = (): Output => {
  const authService = useAuthService();

  if (authService.isLoading || !authService.userState.isAuthenticated) {
    return DEFAULT_USER_TYPE;
  }

  const {
    userData: { email, isSubscriber, uuid }
  } = authService.userState;

  return {
    email: email || null,
    isAnonymousUser: false,
    isBrandPassUser: isSubscriber,
    isFreeUser: !isSubscriber,
    isLoggedIn: true,
    isOutsidePlusUser: isSubscriber,
    userType: isSubscriber ? UserTypes.OPlus : UserTypes.Free,
    uuid: uuid || null
  };
};
