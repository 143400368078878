import type { AuthState } from '../auth';

import type { OutsideHeaderEventDomain } from '.';

export enum AuthEventID {
  AUTH_STATE_UPDATE = 0,
  REFRESH_APOLLO = 1,
  SET_DISCOUNT = 2,
  NAVIGATE_TO_SIGN_IN_URL = 3
}
export type AuthEvent = {
  domain: OutsideHeaderEventDomain;
  id: AuthEventID;
  payload?: Partial<AuthState>;
};
