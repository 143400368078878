import { useRouter } from 'next/router';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { useEffect } from 'react';

import { useRegisterWatchRegwallAnalytics } from './useRegisterWatchRegwallAnalytics';

const ALLOWED_ROUTES = ['/fcc-applications'];

// watch-regwall
export const useWatchRegWall = (mediaItem?: MediaItem) => {
  const { pathname, query } = useRouter();
  useRegisterWatchRegwallAnalytics();

  useEffect(() => {
    // hide modal for non-free mediaItems in fullscreen mode (because those already have Piano modals)
    if (
      (query?.mode === 'fullscreen' &&
        mediaItem &&
        mediaItem.meterFlow !== 'FREE') ||
      ALLOWED_ROUTES.some((route) => pathname === route)
    ) {
      document.body.classList.add('tp-modal-hidden');
      return;
    }

    document.body.classList.remove('tp-modal-hidden');
  }, [mediaItem, pathname, query?.mode]);
};
