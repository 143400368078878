import type {
  ElementClickedData,
  ElementObservedData,
  IdentifyTriggerPayload,
  PurchaseData,
  RegistrationErrorData
} from '../analytics';

import type { OutsideHeaderEventDomain } from '.';

export enum AnalyticsEventID {
  ELEMENT_CLICKED = 0,
  ELEMENT_OBSERVED = 1,
  PURCHASE_COMPLETE = 2,
  REGISTRATION_COMPLETE = 3,
  REGISTRATION_ERRORS_RECEIVED = 4,
  IDENTIFY = 5
}
export type AnalyticsEvent = {
  domain: OutsideHeaderEventDomain.ANALYTICS;
  id: AnalyticsEventID;
  payload:
    | ElementClickedData
    | ElementObservedData
    | IdentifyTriggerPayload
    | PurchaseData
    | RegistrationErrorData
    | null;
};
