import Container from 'components/01-atoms/Container';
import Flex from 'components/01-atoms/Flex';
import Heading from 'components/01-atoms/Heading';
import Link from 'next/link';
import Icon from 'components/01-atoms/Icon';
import { routes } from 'lib/routes';

import {
  watchAppsList,
  watchContentProvidersHeading,
  watchContentProvidersSubheading,
  watchContentProvidersText,
  watchLiveChannelList
} from './constants';
import LogosGrid from './LogosGrid';

const WatchContentProvidersList = () => (
  <section
    className="bg-neutral-800 w-full py-16 narrow-x:py-20 overflow-clip scroll-mt-header"
    id="watch-here"
  >
    <Container
      classes="grid grid-cols-1 narrow:grid-cols-2 gap-12 narrow:gap-20 wide:gap-40"
      maxSize="x-large"
    >
      <Flex className="flex-col items-start justify-start font-plus-jakarta-sans wide:justify-center wide-x:max-w-xl narrow:order-2">
        <Heading
          classes="text-white mb-4"
          level={2}
          responsiveVariant="large"
          text={watchContentProvidersHeading}
        />
        <p className="text-white text-18 narrow-x:text-20 mb-8">
          {watchContentProvidersSubheading}
        </p>
        <div className="text-dark-gray text-16">
          {watchContentProvidersText}
        </div>
        <div className="mt-8">
          <Link
            className="flex items-center gap-2 whitespace-nowrap text-white hover:text-btn-secondary-hover"
            href={routes.fccApplications()}
          >
            <Icon
              className="text-btn-secondary-hover"
              type="infoOutline"
              width={20}
            />
            <p className="text-16">FCC Applications</p>
          </Link>
        </div>
      </Flex>
      <Flex className="flex-col gap-10">
        <LogosGrid {...watchAppsList} />
        <LogosGrid {...watchLiveChannelList} />
      </Flex>
    </Container>
  </section>
);

export default WatchContentProvidersList;
