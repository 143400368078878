// Custom Next Configuration.
const { withSentryConfig } = require('@sentry/nextjs');

const config = require('../../next.global.config');

const domains = [
  'cdn.jwplayer.com',
  'wp.watch.outsideonline.com',
  'test-pom-vid.pantheonsite.io',
  'outsideonline.com',
  'www.outsideonline.com'
];

const mergedConfig = {
  ...config,
  async headers() {
    return [
      {
        headers: [
          {
            key: 'X-Content-Type-Options',
            value: 'nosniff'
          },
          {
            key: 'Strict-Transport-Security',
            value: 'max-age=63072000; includeSubDomains; preload'
          }
        ],
        // Apply these headers to all routes in your application.
        source: '/:path*'
      }
    ];
  },
  images: {
    domains,
    formats: ['image/avif', 'image/webp'],
    remotePatterns: [
      {
        hostname: '*.cloudfront.net',
        protocol: 'http'
      }
    ]
  },
  async redirects() {
    return [
      {
        destination: '/series/ironman-703-oceanside/6XTsutNz-RX3KqteL',
        permanent: true,
        source: '/series/ironman-703-oceanside/5mfODd6l-RX3KqteL'
      }
    ];
  },
  async rewrites() {
    const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
    const rewriteRules = [
      {
        destination: '/api/ads',
        source: '/ads.txt'
      },
      {
        destination: '/api/app-ads',
        source: '/app-ads.txt'
      }
    ];

    if (isProduction) {
      // block accessing Sentry source maps
      return {
        beforeFiles: [
          {
            destination: '/404',
            source: '/:path*.map'
          },
          ...rewriteRules
        ]
      };
    }

    return rewriteRules;
  },
  swcMinify: false
};

const sentryWebpackPluginOptions = {
  hideSourceMaps: false,
  silent: false,
  widenClientFileUpload: true
};

module.exports = process.env.SENTRY_ENVIRONMENT
  ? withSentryConfig(mergedConfig, sentryWebpackPluginOptions)
  : mergedConfig;
