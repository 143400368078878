import type { AmplitudeFlags, FeatureFlags, FlagKey } from '../types';

export const parseFeatureFlags = (flags: AmplitudeFlags) =>
  (Object.keys(flags) as FlagKey[]).reduce((acc, key) => {
    const flag = flags[key];

    if (flag?.value === 'on') {
      acc[key] = {
        isEnabled: true,
        payload: flag.payload
      };
    } else {
      acc[key] = {
        isEnabled: false
      };
    }
    return acc;
  }, {} as FeatureFlags);
