import clsx from 'clsx';

import PlaySVG from '../../../public/icons/play.svg';
import LockSVG from '../../../public/icons/lock.svg';
import InfoSVG from '../../../public/icons/info.svg';
import InfoOutlineSVG from '../../../public/icons/info-outline.svg';
import InfoWhiteSVG from '../../../public/icons/info-white.svg';
import ChevronLeftSVG from '../../../public/icons/chevron-left.svg';
import ChevronRightSVG from '../../../public/icons/chevron-right.svg';
import ChevronDownSVG from '../../../public/icons/chevron-down.svg';
import CheckSVG from '../../../public/icons/check.svg';
import CalendarSVG from '../../../public/icons/calendar.svg';
import PinSVG from '../../../public/icons/pin.svg';
import CrossSVG from '../../../public/icons/cross.svg';
import ArrowLeftCircleSVG from '../../../public/icons/arrow-left-circle.svg';
import PlayFullSVG from '../../../public/icons/play-full.svg';
import EyeVisibleSVG from '../../../public/icons/eye-visible.svg';
import EyeHiddenSVG from '../../../public/icons/eye-hidden.svg';
import CloseSVG from '../../../public/icons/close.svg';
import MagnifierSVG from '../../../public/icons/magnifier.svg';
import TvSVG from '../../../public/icons/tv.svg';
import PlayTrailerSVG from '../../../public/icons/play-trailer.svg';
import Box from '../Box';

const iconMap = {
  arrowLeftCircle: ArrowLeftCircleSVG,
  calendar: CalendarSVG,
  check: CheckSVG,
  chevronDown: ChevronDownSVG,
  chevronLeft: ChevronLeftSVG,
  chevronRight: ChevronRightSVG,
  close: CloseSVG,
  cross: CrossSVG,
  eyeHidden: EyeHiddenSVG,
  eyeVisible: EyeVisibleSVG,
  info: InfoSVG,
  infoOutline: InfoOutlineSVG,
  infoWhite: InfoWhiteSVG,
  lock: LockSVG,
  magnifier: MagnifierSVG,
  pin: PinSVG,
  play: PlaySVG,
  playFull: PlayFullSVG,
  playTrailer: PlayTrailerSVG,
  tv: TvSVG
};

export type Type = keyof typeof iconMap;

type Props = {
  className?: string;
  type: Type;
  width?: number | string;
};

const Icon = ({ className, type, width = '100%' }: Props) => {
  const IconCmp = iconMap[type];
  const widthStyle = typeof width === 'string' ? width : `${width}px`;

  return (
    <Box className={clsx('h-auto', className)} style={{ width: widthStyle }}>
      <IconCmp />
    </Box>
  );
};

export default Icon;
