import type { ExperimentClient } from '@amplitude/experiment-js-client';
import { Experiment } from '@amplitude/experiment-js-client';
import { ANONYMOUS_USER_ID } from 'helpers/constants';

import { parseFeatureFlags } from './helpers';
import {
  AMPLITUDE_EXPERIMENT_API_KEY,
  AMPLITUDE_FLAGS_KEYS
} from './constants';

let experiment: ExperimentClient;

export const fetchFeatureFlags = async (uuid: string) => {
  if (!experiment) {
    experiment = Experiment.initialize(AMPLITUDE_EXPERIMENT_API_KEY);
  }

  await experiment.start();
  await experiment.fetch(
    { user_id: uuid || ANONYMOUS_USER_ID },
    {
      flagKeys: AMPLITUDE_FLAGS_KEYS
    }
  );

  const variants = experiment.all();

  return parseFeatureFlags(variants);
};
